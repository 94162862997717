import React from 'react'

import Page from '../components/Page'
import Journal from '../components/Journal'
import Project from '../components/Project'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import useStoryblok from '../utils/storyblok'

const NotFoundPage = ({ location }) => {
  const story = useStoryblok(null, location)
  console.log('story :>> ', story)

  let content = (
    <div className="main__padded">
      <h1>Page not found</h1>
    </div>
  )
  if (story && story.content) {
    if (story.content.component === 'Page') {
      content = <Page blok={story.content} />
    } else if (story.content.component === 'Journal') {
      content = <Journal blok={story.content} />
    } else if (story.content.component === 'Project') {
      content = <Project blok={story.content} />
    }
  }

  return (
    <Layout location={location}>
      <Seo title="404" />
      {content}
    </Layout>
  )
}

export default NotFoundPage
